import auth from "@/auth";import { useUserStore } from "@/stores/userStore";
import axios from "axios";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import { useErrorHandlingStore } from "./stores/errorHandling";

loadFonts();
const app = createApp(App).use(router).use(vuetify).use(createPinia());

const userStore = useUserStore();
const errorHandlingStore = useErrorHandlingStore();

//This method I need
async function authenticateUser() {
  await auth.setToken();
  if (!userStore.isAuthenticated) {
    await auth.login();
  } else {
    addInterceptor();
    app.mount("#app");
  }
}

authenticateUser().then(() => {
  console.log("Login Success");
});

const addInterceptor = () => {
  axios.interceptors.request.use(async (config) => {
    const user = await auth.getUser();
    config.headers.Authorization = `Bearer ${user.id_token}`;
    return config;
  });
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("setErrorHandling", error);
      if (error.response.status != 409) {
        errorHandlingStore.setErrorHandling(
          true,
          error?.response?.data?.message ||
            error?.response?.data?.businessMessage ||
            error?.response?.data?.title ||
            "Something went wrong."
        );
      }
      return Promise.reject(error);
    }
  );
};
