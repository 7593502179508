<template>
  <v-dialog v-model="dialog" max-width="800px" max-height="3000px" persistent>
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        Inactivity Warning
        <div>
          <v-icon @click="stayLoggedIn" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text>
          <div class="d-flex justify-content-between">
            <div class="mpms-env-label d-flex justify-center m-20">
              You have been inactive for more than
              {{ idleLimit / 60 }}.0 minutes.To remain logged in, click the
              "Stay Logged In" button.
            </div>
          </div>
          <div>
            <v-row class="d-flex justify-center align-center mb-2">
              <v-col
                cols="auto"
                class="bordered-row col-custom-width"
                style="flex: 2"
                >Time remaining before you are automatically signed out:</v-col
              ><v-col
                cols="auto"
                class="bordered-row custom-width"
                style="flex: 1"
                >{{ formattedCountdown }}</v-col
              ></v-row
            >
          </div>
          <v-card-actions>
            <div class="d-flex justify-center align-center">
              <PrimaryButton
                @click="logout"
                :buttonText="'LOGOUT'"
              ></PrimaryButton>
              <PrimaryButton
                @click="stayLoggedIn"
                :buttonText="'STAY LOGGED IN'"
                class="ml-10"
              ></PrimaryButton>
            </div>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import router from "@/router";
import { useUserStore } from "@/stores/userStore";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
export default {
  name: "IdleTimer",
  components: {
    PrimaryButton,
  },
  setup() {
    const dialog = ref(false);
    const countdownTime = ref(300);
    const countdown = ref(300); // Countdown for auto logout secs
    const idleLimit = ref(25 * 60); // Idle timeout in seconds
    let idleTimer;
    let countdownTimer;
    let startTime = Date.now();
    const showDialog = () => {
      dialog.value = true;
      startCountdown(); // Start the countdown when the dialog is shown
    };

    const resetCountdown = () => {
      countdown.value = countdownTime.value;
      clearInterval(countdownTimer);
    };

    const startIdleTimer = () => {
      idleTimer = setTimeout(showDialog, idleLimit.value * 1000); // Show dialog after idle limit
    };

    const startCountdown = () => {
      const initialTime = countdown.value;
      startTime = Date.now();
      const updateCountdown = () => {
        const now = Date.now();
        const elapsed = Math.floor((now - startTime) / 1000); // Calculate elapsed time in seconds
        countdown.value = Math.max(0, initialTime - elapsed);
        if (countdown.value <= 0) {
          logout(); // Auto logout when countdown reaches 0
        } else {
          requestAnimationFrame(updateCountdown); // Continue the countdown
        }
      };
      requestAnimationFrame(updateCountdown); // Start the countdown
    };

    const stayLoggedIn = () => {
      dialog.value = false;
      resetCountdown(); // Reset countdown only
      startIdleTimer(); // Restart the idle timer
      startTime = Date.now();
      startCountdown();
    };

    const logout = () => {
      dialog.value = false;
      clearTimeout(idleTimer); // Clear idle timer
      clearInterval(countdownTimer);

      router.push({ path: "/logout", query: { sessionExpired: "true" } });
    };

    const init = async () => {
      try {
        const userStore = useUserStore();
        const response = await userStore.getTimeOutData();
        idleLimit.value = response.data.IDLE_TMOT * 60; // Convert minutes to milliseconds
        countdownTime.value = response.data.LGT_CNTDWN * 60; // Convert minutes to milliseconds
        countdown.value = countdownTime.value;
      } catch (error) {
        console.error(
          "Using default timeout settings due to API error:",
          error
        );
      }
    };

    const registerActivityListeners = () => {
      window.addEventListener("mousemove", handleActivity);
      window.addEventListener("keypress", handleActivity);
      window.addEventListener("click", handleActivity);
      window.addEventListener("scroll", handleActivity);
    };

    const handleActivity = () => {
      if (!dialog.value) {
        resetIdleTimer(); // Reset idle timer only if dialog is not visible
      }
    };

    // Reset the idle timer whenever there is user activity
    const resetIdleTimer = () => {
      clearTimeout(idleTimer); // Clear existing timer
      startIdleTimer(); // Restart the idle timer
    };

    onMounted(() => {
      init();
      registerActivityListeners(); // Register activity listeners
      startIdleTimer(); // Start the idle timer on mount
    });

    onUnmounted(() => {
      clearTimeout(idleTimer); // Cleanup
      clearInterval(countdownTimer); // Clear countdown timer if active
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("click", handleActivity);
      window.removeEventListener("scroll", handleActivity);
    });
    // Computed property to format countdown in minutes and seconds
    const formattedCountdown = computed(() => {
      const minutes = Math.floor(countdown.value / 60);
      const seconds = countdown.value % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`; // Format as "m:ss"
    });

    return {
      dialog,
      countdown,
      formattedCountdown,
      stayLoggedIn,
      logout,
      idleLimit,
    };
  },
};
</script>

<style>
@import "@/assets/css/main.scss";
@import "@/assets/css/color.scss";
@import "@/assets/css/style.scss";
@import "@/modules/gcmsmain/gcmsmainpage/assets/header.scss";
.bordered-row {
  border: 1px solid black; /* Add border to the entire row */
  padding: 8px; /* Optional: Add padding for better spacing */
}

.custom-width {
  max-width: 60px; /* Set the desired maximum width */
  width: 50%; /* Ensure the row takes the full width of its container but limited by max-width */
}

.col-custom-width {
  max-width: 500px; /* Set the desired maximum width */
  width: 50%; /* Ensure the row takes the full width of its container but limited by max-width */
}
</style>
