<template>
  <v-app>
    <v-main>
      <div><IdleTimerPopup></IdleTimerPopup></div>
      <div><AutoLogout></AutoLogout></div>
      <div v-if="isPremissionRetreived"> <router-view /></div>
    </v-main>
  </v-app>
  <SharedConfirmationDialog
    ref="showUploadECSServiceError"
  ></SharedConfirmationDialog>
</template>

<script>
import { onMounted, computed, ref, watch } from "vue";
import IdleTimerPopup from "@/modules/timeout/IdleTimerPopup.vue";
import AutoLogout from "@/modules/timeout/AutoLogout.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import { useUserStore } from "@/stores/userStore";
import SharedConfirmationDialog from "@/modules/shared/components/SharedConfirmationDialog.vue";
import axios from "axios";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { useDocumentStore } from "./stores/document";
export default {
  name: "App",
  components: {
    IdleTimerPopup,
    AutoLogout,
    SharedConfirmationDialog,
  },
  setup(props, context) {
    const userStore = useUserStore();
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const documentStore = useDocumentStore();
    const isUploadErrorDialogVisible = computed(
      () => documentStore.isUploadErrorDialogVisible
    );
    const user = computed(() => userStore.loggedInUser);
    const isAuthenticated = computed(() => userStore.isAuthenticated);
    const showUploadECSServiceError = ref(null);
    const isPremissionRetreived = ref(false); // Initial value set to false
    
    onMounted(async () => {
      if (isAuthenticated) {
        const userData = {
          userRole: user?.value?.groups.split(",")[0].split("=")[1],
          username: user?.value?.name + " " + user?.value?.family_name,
          firstname: user?.value?.name,
          lastname: user?.value?.family_name,
          userID: user?.value?.sub,
          distictId: "",
        };
        sharedUserService.setSharedUserData(userData);
        addUser();
        if (!isPremissionRetreived.value) { 
          await userRoleBasedAccessService.createUserMap();
          isPremissionRetreived.value = true; 
        }        
      }
    });
    watch(
      () => [isUploadErrorDialogVisible],
      () => {
        if (isUploadErrorDialogVisible && documentStore.uploadErrorMessage) {
          showUploadECSDialog();
        }
      },
      { deep: true }
    );
    function addUser() {
      if (user.value.sub !== "") {
        axios
          .post(CONFIG_ENDPOINT.ADMIN_MANAGE_USER, {
            userID: user.value.sub,
            userName: user.value.name + " " + user.value.family_name,
            firstname: user.value.name,
            lastname: user.value.family_name,
            userRole: user.value.groups.split(",")[0].split("=")[1],
            districtofficeid: 11,
          })
          .then((response) => {
            context.emit("Manage User add to Database", response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    const showUploadECSDialog = () => {
      const confirmationMessage = documentStore.uploadErrorMessage;

      showUploadECSServiceError.value
        .open(
          "GCMS Document Management System is Down",
          [confirmationMessage],
          null,
          true
        )
        .then((resolve) => {
          documentStore.resetUpdateErrorMessage();
        });
      //To Show Dialog end
    };
    return {
      user,
      isAuthenticated,
      IdleTimerPopup,
      AutoLogout,
      showUploadECSServiceError,
      isPremissionRetreived,
    };
  },
};
</script>
<style>
@import "@/assets/css/main.scss";
@import "@/assets/css/color.scss";
@import "@/assets/css/style.scss";
</style>
